









import Messages from '@/definitions/messages'

export default {
  name: 'Error',
  data() {
    return {
      err: 'none',
    }
  },
  components: {
  },
  computed: {
    errorMsg() { return Messages.errorOccurred },
  },
  beforeMount() {
    const s = localStorage.error
    this.err = s
    localStorage.error = 'none'
  },
}
